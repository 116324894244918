<template>
  <div class="section2">
    <img src="./s2/bg.jpg" alt="" class="bg-img" v-if="!isMobile">
    <div class="light" data-aos="fade" data-aos-delay="0" v-if="!isMobile">
    <img src="./s2/光.jpg" alt="光" class="">
    <img src="./s2/光.jpg" alt="光" class="animate1">
    <img src="./s2/光.jpg" alt="光" class="animate2">
    </div>
    <img src="./s2/logo.png" alt="" class="logo" v-if="!isMobile" data-aos="fade-down" data-aos-delay="200">
    <!-- <div class="title-bg">
      <div class="text" v-if="isMobile">
        <div
          class="title"
          data-aos="fade-down"
          data-aos-delay="0"
        >樹海恬靜學區 爭藏氧森別墅</div>
        <div
          class="desc"
          data-aos="fade-down"
          data-aos-delay="200"
        >步行2分鐘抵高雄大學，自然生態日夜淨化空氣ING</div>
      </div>
    </div> -->
    <Map :bgSrc="bgSrc" :hand="hand" v-if="isMobile">
    <div class="light">
    <img src="./s2/光.jpg" alt="光" class="">
    <img src="./s2/光.jpg" alt="光" class="animate1">
    <img src="./s2/光.jpg" alt="光" class="animate2">
    </div>
      <img src="./s2/logo.png" alt="" class="logo">
    </Map>
  </div>
</template>
<style lang="scss" scoped>
@import '@/assets/style/function.scss';

.bg {
  background-color: #fff;
  background-position: 100% 0%;
  background-size: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  // background-image: url('./s2_bg.jpg');
}

.bg-img {
  width: 100vw;
  height: 100vh;
  min-height: size(1080);
  position: absolute;
  top: 0;
  left: 0;
  height: auto;
  display: block;
  object-fit: cover;
  margin-top: 0;
  &:nth-child(1) {
    position: relative;
  }
}

.light {

  @include img_l_pc(403, 359, 985);
  mix-blend-mode: screen;
  img{width: 100%;mix-blend-mode: screen;position: absolute;top: 0;left: 0;
    opacity: .8;}
}

.animate1{transform: scaleX(-1);}
.animate2{
  animation: pulse 0.5s ease-out infinite alternate;
    opacity: 1;
}

.logo {
  @include img_l_pc(126, 546, 1124);
}

// .animate2 {
//   animation: pulse 0.8s 0.3s ease-in-out infinite;
// }

@keyframes pulse {
  to {
    opacity: 0;
  }
}
@media only screen and (max-width: 1440px) and (min-width: 1025px) {
  .fullscreen {
    height: 100vh;
  }

  .text {
    top: 40px;
  }

  .title {
    font-size: 90px;
  }
}

/* 螢幕尺寸標準 */
/* 平板尺寸 */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  .bg {
    background-size: 1920px auto;
    background-position: center;
  }
  .txt {
    height: auto;
    margin-top: 100px;
  }

  .title {
    font-size: 52px;
  }

  .desc {
    font-size: 24px;
  }

  .text {
    top: 0;
  }
}

@media screen and (max-width: 767px) {
  .bg {
    background-position: 55%;
    // background-image: url('./s3_bg_m.jpg');
  }
  .items {
    position: absolute;
    top: 0;
    left: 0;
    // width: 100vw;
    z-index: 1;
  }

  .light {
    @include img_l_m(225, 201, 525);
    mix-blend-mode: screen;
    z-index: 2;
  }

  .logo {
    @include img_l_m(71, 307, 604);z-index: 2;
  }
}
</style>

<script>
import { isMobile } from '@/utils'
import Map from '@/components/Map.vue'
export default {
  name: 'section2',

  components: {
    Map,
  },

  data() {
    return {
      isMobile,
      // tagList: [require('./s3_title.png')],

      bgSrc: require('./s2/bg.jpg'),
      // bgmSrc: require('./s3_bg_m.jpg'),
      hand: require('./s2/小手.png'),
    }
  },

  mounted() {},
}
</script>
