import { isMobile } from '../utils/index'
export default {
  address: '新北市汐止區康寧街459巷3號',
  googleSrc:
    'https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d7227.937753150488!2d121.631229!3d25.069044!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x345d534c7e98316d%3A0x39ad842b59f1c2d9!2z5p2x5Z-O5aSn5aKD5o6l5b6F5Lit5b-D!5e0!3m2!1szh-TW!2stw!4v1606301085790!5m2!1szh-TW!2stw',
  googleLink: 'https://goo.gl/maps/dRxnMawxRVzR2AcG6',
  phone: '02-2692-1155',
  fbLink: 'https://www.facebook.com/東城大境-584895791902170',
  fbMessage: 'https://m.me/584895791902170',
  caseName: '東城大境',
  indigatorLength: 10,

  houseInfos: [
    ['投資興建', '合康建設股份有限公司'],
    ['營造公司', '閤康聯合建築師事務所'],
    ['營造公司', '洛城營造'],
    ['企劃銷售', '新高創廣告有限公司'],
    ['景觀設計', '六國景觀-蘇瑞泉大師'],
    ['建照號碼', '108建字第0256號'],
  ],

  gtmCode: ['5N96QXB'], // 可放置多個
  recaptcha_site_key_v2: '6Lep-78UAAAAAMaZLtddpvpixEb8cqu7v7758gLz',
  recaptcha_site_key: '6Lck-L8UAAAAABxfvNA1NJuBrdl3iLFc3GkudC8s', // recaptcha v3
  recaptcha_user_token: '6Lck-L8UAAAAAIcvenwGDl8_Q1tKbrEYsKuriePa',
  order: {
    title: isMobile ? '預約賞屋' : '預約賞屋',
    subTitle: ''
  }
}
